export const SEGMENTATION_MODELS = {
    MediapipeSelfie: 'selfie',
    DeepLabV3: 'deeplabV3',
} as const;

export type SegmentationModel =
    (typeof SEGMENTATION_MODELS)[keyof typeof SEGMENTATION_MODELS];

/**
 * Segmentation model asset that can be used to load a segmentation model.
 */
export interface SegmentationModelAsset {
    path: string;
    modelName: SegmentationModel;
}

export interface ImageSegmenterOptions {
    /**
     * The canvas element to bind textures to. This has to be set for GPU
     * processing. The task will initialize a WebGL context and throw an error if
     * this fails (e.g. if you have already initialized a different type of
     * context).
     */
    canvas?: OffscreenCanvas;
    modelAsset?: SegmentationModelAsset;
    /** Overrides the default backend to use for the provided model. */
    delegate?: 'CPU' | 'GPU' | undefined;
}

export interface SegmenterOptions {
    basePath?: string;
    output?: OffscreenCanvas | null;
    processingWidth?: number;
    processingHeight?: number;
    imageSegmenterOptions?: ImageSegmenterOptions;
}

export interface SegmentationSmoothingConfig {
    combineWithPreviousRatio: number;
}

/**
 * Wrapper for a mask produced by a Segmentation Task.
 */
export interface MPMask {
    /** Returns the canvas element that the mask is bound to. */
    readonly canvas: OffscreenCanvas | undefined;
    /** Returns the width of the mask. */
    readonly width: number;
    /** Returns the height of the mask. */
    readonly height: number;
    /**
     * Returns whether this `MPMask` contains a mask of type `Uint8Array`.
     */
    hasUint8Array(): boolean;
    /**
     * Returns whether this `MPMask` contains a mask of type `Float32Array`.
     */
    hasFloat32Array(): boolean;
    /**
     * Returns whether this `MPMask` contains a mask of type `WebGLTexture`.
     */
    hasWebGLTexture(): boolean;
    /**
     * Returns the underlying mask as a Uint8Array`. Note that this involves an
     * expensive GPU to CPU transfer if the current mask is only available as a
     * `WebGLTexture`.
     *
     * @return The current data as a Uint8Array.
     */
    getAsUint8Array(): Uint8Array;
    /**
     * Returns the underlying mask as a single channel `Float32Array`. Note that
     * this involves an expensive GPU to CPU transfer if the current mask is
     * only available as a `WebGLTexture`.
     *
     * @return The current mask as a Float32Array.
     */
    getAsFloat32Array(): Float32Array;
    /**
     * Returns the underlying mask as a `WebGLTexture` object. Note that this
     * involves a CPU to GPU transfer if the current mask is only available as
     * a CPU array. The returned texture is bound to the current canvas (see
     * `.canvas`).
     *
     * @return The current mask as a WebGLTexture.
     */
    getAsWebGLTexture(): WebGLTexture;
    /**
     * Creates a copy of the resources stored in this `MPMask`. You can
     * invoke this method to extend the lifetime of a mask returned by a
     * MediaPipe Task. Note that performance critical applications should aim to
     * only use the `MPMask` within the MediaPipe Task callback so that
     * copies can be avoided.
     */
    clone(): MPMask;
    /**
     * Frees up any resources owned by this `MPMask` instance.
     *
     * Note that this method does not free masks that are owned by the C++
     * Task, as these are freed automatically once you leave the MediaPipe
     * callback. Additionally, some shared state is freed only once you invoke
     * the Task's `close()` method.
     *
     * @export
     */
    close(): void;
}
